<template>
  <div>
    <div class="d-flex justify-start align-center px-5" style="height:60px;background-color:#fff;">
      <v-icon color="#d31145" @click="goback">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700;color:#d31145">
        {{ $route.query.id ? 'Ubah Kategori Produk' : 'Tambah Kategori Produk'}}
      </span>
    </div>
    <v-divider></v-divider>

    <!-- <div class="d-flex justify-center align-center" style="padding-top: 20px">
      <v-card
        flat
        class="pa-0 c-p"
        style="background: transparent;"
      >
        <image-input v-model="avatar" type="uploadCover">
          <div slot="activator">
            <v-avatar
              tile
              size="100"
              height="100"
              class="grey lighten-1 cursor-pointer rounded-xl"
              v-ripple
            >
              <v-img
                v-if="!avatar && addImage === ''"
                :src="form.image_url"
                class="mx-auto"
                width="100"
                height="100"
              >
                <v-icon
                  v-if="!avatar && addImage === '' && form.image_url === ''"
                  color="grey darken-3"
                  style="z-index:2"
                  large
                  >mdi-camera-plus</v-icon
                >
                <div style="color:#fff;background:black;opacity:0.5;position: absolute;width:100%;height:100%;"></div>
              </v-img>
              <div
                class="px-5"
                v-if="addImage && avatar.url === undefined"
              >
                <v-progress-linear
                  color="#d31145"
                  indeterminate
                  rounded
                  height="6"
                  class="mx-4 mt-4"
                  style="width: 80px;"
                >
                </v-progress-linear>
                <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                  Mengupload Gambar . . .
                </p>
              </div>
              <v-img
                v-if="avatar && !addImage"
                :src="form.image_url"
                :alt="form.image_url"
                class="mx-auto"
                width="100"
                height="100"
              >
                <v-icon
                  color="white"
                  size="30"
                  v-if="avatar && !addImage"
                  style="position: absolute; top: 3px; right: 5px;z-index:2">
                  mdi-camera-retake
                </v-icon>
                <div style="color:#fff;background:black;opacity:0.5;position: absolute;width:100%;height:100%;"></div>
              </v-img>
              
            </v-avatar>
          </div>
        </image-input>
      </v-card>
      <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
        {{ error.image_url }}
      </p>
    </div> -->

    <div class="d-flex justify-center align-center py-6">
      <v-card
        flat
        class="pa-0 c-p"
        style="background: transparent;">
        <image-input v-model="avatar" type="uploadCover">
          <div slot="activator">
            <v-avatar
              tile
              size="120"
              class="grey lighten-1 cursor-pointer rounded-xl">
              <v-img
                v-if="!avatar && addImage === ''"
                :src="form.image_url"
                class="mx-auto"
                width="120"
                height="120"
                gradient="to top right, rgba(0,0,0,.1), rgba(0,0,0,.1)">
                <v-icon
                  v-if="!avatar && addImage === '' && form.image_url === ''"
                  color="white"
                  style="z-index:2"
                  size="40">
                  mdi-camera-plus
                </v-icon>
              </v-img>

              <div
                class="px-5"
                v-if="addImage && avatar.url === undefined">
                <v-progress-linear
                  color="#d31145"
                  indeterminate
                  rounded
                  height="6"
                  class="mx-4 mt-4"
                  style="width: 80px;">
                </v-progress-linear>

                <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                  Mengupload Gambar . . .
                </p>
              </div>

              <v-img
                v-if="avatar && !addImage"
                :src="form.image_url"
                :alt="form.image_url"
                class="ma-auto"
                gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                width="120"
                height="120">
                <v-icon
                  color="white"
                  size="40"
                  v-if="avatar && !addImage">
                  mdi-camera-retake
                </v-icon>
              </v-img>
            </v-avatar>
          </div>
        </image-input>
      </v-card>

      <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
        {{ error.image_url }}
      </p>
    </div>

    <div class="d-flex justify-center align-center" style="padding: 15px 20px 0px 20px;">
      <v-row>
        <v-col>
          <div class="mb-2 subtitle-2 font-weight-bold text-title">
            Nama Kategori Produk
            <!-- <v-icon size="18" color="#FA91AD">mdi-information-outline</v-icon> -->
          </div>
          <v-text-field
            v-model="form.name"
            placeholder="Tuliskan disini"
            required
            color="#d31145"
            maxlength="50"
            autocomplete="off"
            hide-details="auto">
          </v-text-field>
          <div style="display: flex;justify-content:space-between;padding-top:8px;">
            <div class="text-required">Wajib</div>
            <div style="font-size: 11px;">
              <span style="font-weight: bold">{{ form.name.length }} </span>
              <span style="color:#B5B5B5"> dari 50 Karakter</span>  
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    
    <div style="position:absolute;bottom:0;width:100%;background-color:#fff;height:60px" class="d-flex justify-center align-center px-6">
      <v-btn 
        @click="toValidation()" 
        :loading="process.run" 
        :disabled="process.run"
        rounded
        style="flex:1"
        elevation="0" 
        color="#d31145" 
        class="text-capitalize white--text mr-2 d-flex justify-space-between">
        <v-icon color="white" left>mdi-check-circle-outline</v-icon>
        <span>
          {{ $route.query.id ? 'Simpan Perubahan' : 'Tambahkan Data' }}
        </span>
      </v-btn>
      <v-btn 
        @click="goback"
        :loading="process.run" 
        :disabled="process.run" 
        rounded 
        style="flex:.5"
        outlined
        elevation="0" 
        color="#A8A8A8" 
        class="text-capitalize">
        <span>
          Batalkan
        </span>
      </v-btn>
    </div>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          {{ $route.query.id ? 'Ubah Kategori Produk' : 'Tambah Kategori Produk' }}
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin {{ $route.query.id ? 'mengubah' : 'menambahkan' }} Kategori Produk &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ form.name }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-center align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="save()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, {{ $route.query.id ? 'Simpan Perubahan' : 'Tambahkan Data' }}
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

  </div>
</template>

<script>
import ImageInput from "@/components/ImageInput";
export default {
  data: () => ({
    form: {
      name: "",
      image_url: "https://sodapos.com/media/2021/12/image/kategori-1638863913.png",
    },
    process: {
      run: false
    },
    avatar: null,
    addImage: "",
    error: {
      image_url: "",
    },
    confirmation: false,
  }),
  components: {
    ImageInput
  },
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {

  },
  created() {

  },
  mounted(){
    // console.log(this.$route);
    this.avatar = {
      url: this.$route.query.image_url ? this.$route.query.image_url : "https://sodapos.com/media/2021/12/image/kategori-1638863913.png",
      add: false
    }

    this.form.name = this.$route.query.name ? this.$route.query.name : ""
    this.form.image_url = this.$route.query.image_url ? this.$route.query.image_url : "https://sodapos.com/media/2021/12/image/kategori-1638863913.png";
    this.addImage = false
  },
  methods: {
    goback() {
      this.$router.push('/master/category')
    },
    toValidation(){
      if(!this.form.name){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Nama Kategori tidak boleh kosong"
      }else{
        this.confirmation = true
      }
    },
    async save(){
      this.$store.state.overlay.state = true
      this.confirmation = false
      let data = {}
      let url = ""
      if(!this.$route.query.id){
        url = "pos/category/add"
        data = {
          name: this.form.name,
          image_url: this.form.image_url
        }
      }else{
        url = "pos/category/update"
        data = {
          id: this.$route.query.id,
          name: this.form.name,
          image_url: this.form.image_url
        }
      }
      let res = await this.$post(url, data);

      if (res.status == 200) {
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        this.$router.push(`/master/category`)
      }

      this.$store.state.overlay.state = false

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }
      
      this.$store.state.snackbar.state = true
      this.$store.state.snackbar.content = res.message
      this.$store.state.overlay.state = false;
    }
  },
}
</script>